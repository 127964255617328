import { useCallback } from "react";
import { useAddTariffPromotionToOffer } from "../useAddTariffPromotionToOffer";
import { MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY } from "../../../constants/const";
import { PortfolioKeys } from "../../../core/entities/Product/IProduct";
import { IAdditionalDevices } from "../../../core/entities/PencilSelling/CartItem/ICartItem";
import { TariffWorldKeys } from "../../../core/repositories/ProductsRepository/DefinitionKeys";
import {
  CustomPromotionTypes,
  ICustomPromotion,
} from "../../../core/entities/PencilSelling/ICustomPromotion";
import { PromotionPriceType } from "../../../core/entities/Product/IDiscount";
import { useStores } from "../../../stores/PrivateClient";
import { ITariff as ITariffProduct } from "../../../core/entities/Product/Tariff/ITariff";
import { IBenefitsData } from "../../../core/entities/MagentaNext/Tariff/ITariff";
import { ICard } from "../../../core/entities/Product/Card/ICard";
import {
  ICartConfigurationType,
  IMobileSettings,
} from "../../../core/entities/PencilSelling/Configurations/IConfigurations";
import {
  getTariffDataForCart,
  tariffProductDescriptionMap,
} from "../../../helpers/TariffSelection";
import { getMobileBenefitSettings } from "../../../helpers/GetMobileBenefitSettings";
import { getSecondaryCardDataForCart } from "../../../helpers/GetSecondaryCardDataForCart";
import { createSecondaryCardKey } from "../../../helpers/CreateSeconaryCardKey";

interface ISecondaryCardsItem extends ICard {
  additionalDevices: IAdditionalDevices[];
}

interface IMagentaCalculatorConfig {
  hasProvisionFee: boolean;
  loyaltyBonus: number;
  mainCard: ITariffProduct;
  secondaryCards: ISecondaryCardsItem[];
  benefits: IBenefitsData;
  smartphoneName: string;
  smartphonePrice: string;
  promotions: ICustomPromotion[];
}

export const useApplyMagentaCalculatorConfig = () => {
  const { offerStore, configurationsStore, productsStore } = useStores();
  const { addPromotion } = useAddTariffPromotionToOffer(offerStore.baseOffer);

  const applyMagentaCalculatorConfig = useCallback(() => {
    const magentaCalculatorConfig = localStorage.getItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY
    );
    // TODO adjust smartphone validation
    if (!magentaCalculatorConfig) return;

    const mobileConfiguration = configurationsStore.getConfigurationsByType(
      ICartConfigurationType.MOBILE
    )[0];
    const secondaryCardsConfiguration =
      configurationsStore.getConfigurationByKey(
        mobileConfiguration.linkedConfigurationKey
      );

    const {
      mainCard,
      secondaryCards,
      benefits,
      smartphoneName,
      smartphonePrice,
      promotions,
    } = JSON.parse(magentaCalculatorConfig) as IMagentaCalculatorConfig;

    const mobileTariff = productsStore.tariffs[PortfolioKeys.MOBILE].find(
      (tariff) => tariff.key === mainCard.key
    );
    // Gather useCases to apply all part of configuration
    const tariffSectionConfigApplyHandler = () => {
      const currentMobileSettings = mobileConfiguration.data
        .settings as IMobileSettings;
      mobileConfiguration.data.settings = {
        ...currentMobileSettings,
        tariffWorld: TariffWorldKeys.magenta_mobile,
        level: mainCard.level.key,
      };

      const cartItemData = getTariffDataForCart(
        mobileTariff,
        [],
        smartphoneName
          ? [
              {
                name: smartphoneName,
                price: smartphonePrice ?? "",
                manufacturer: "",
                isAlternative: false,
                suffix: "",
              },
            ]
          : [],
        PortfolioKeys.MOBILE,
        tariffProductDescriptionMap[PortfolioKeys.MOBILE],
        mobileConfiguration.data.settings,
        benefits.isActive,
        false
      );
      // Convert product from calculator to ICartItemData type.
      mobileConfiguration.children.addToCart(cartItemData);
      mobileConfiguration.data.isBenefitActive = benefits.isActive;
      mobileConfiguration.data.benefit = getMobileBenefitSettings(
        benefits.isActive,
        mobileTariff
      );
    };

    const secondaryCardsConfigApplyHandler = () => {
      const inheritedDataVolume = benefits.isActive
        ? mobileTariff.dataVolumeMagentaOne
        : mobileTariff.dataVolume;
      secondaryCards.forEach((card) => {
        const cartItemData = getSecondaryCardDataForCart({
          card,
          additionalDevices: (card.additionalDevices || []).reduce(
            (acc, additionalDevice) =>
              additionalDevice.name
                ? [
                    ...acc,
                    {
                      ...additionalDevice,
                      manufacturer: "",
                      isAlternative: false,
                      suffix: "",
                    },
                  ]
                : acc,
            [] as IAdditionalDevices[]
          ),
          promotions: [],
          note: "",
          cardSettings: {
            inheritedDataVolume,
            benefitIsActive: benefits.isActive,
            tariff: mobileTariff,
          },
        });
        cartItemData.key = createSecondaryCardKey(card.key);

        secondaryCardsConfiguration.children.addToCart(cartItemData);
      });
    };

    const tariffPromotionsApplyHandler = () => {
      const mobileTariffInCart = mobileConfiguration.children.getMobileTariff();
      promotions.forEach((promotion) => {
        const promotionValue = promotion.discount?.value || 0;
        // TODO set up defalut from -> to in case for Monthly promotion type
        // TODO set default from as 1
        const promotionTypeDefineMap = [
          {
            check: () => promotion.contractPeriod <= 0 && promotionValue <= 0,
            promotionType: CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE,
          },
          {
            check: () => promotion.contractPeriod <= 0 && promotionValue > 0,
            promotionType: CustomPromotionTypes.PROMOTION_TYPE_ONCE_REDUCE,
          },
          {
            check: () =>
              (promotion.contractPeriod > 0 && promotionValue > 0) ||
              // This configuration is also treated as 'monthly' because we have contractPeriod > 0
              (promotion.contractPeriod > 0 && promotionValue === 0),
            promotionType: CustomPromotionTypes.PROMOTION_TYPE_MONTHLY_REDUCE,
          },
        ];

        const [{ promotionType }] = promotionTypeDefineMap.filter((item) =>
          item.check()
        );

        const promotionItem = {
          isEditable: false,
          name: promotion.name,
          description: promotion.name,
          key: `${promotion.key}`,
          portfolio: PortfolioKeys.MOBILE,
          type: promotionType,
          totalPriceDescription: null,
          discount: {
            value:
              promotionType ===
              CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE
                ? null
                : promotionValue,
            interval:
              promotionType ===
              CustomPromotionTypes.PROMOTION_TYPE_WITHOUT_PRICE
                ? null
                : promotion.contractPeriod,
            kind: PromotionPriceType.CUSTOM_PROMOTION,
            description: null,
            from: 1,
            to: promotion.contractPeriod,
            priceReduction: null,
          },
        };
        addPromotion(promotionItem, PortfolioKeys.MOBILE);
        mobileTariffInCart.promotions.push(promotionItem);
      });
    };

    tariffSectionConfigApplyHandler();
    secondaryCardsConfigApplyHandler();
    tariffPromotionsApplyHandler();

    //  Remove config from localStorage
    localStorage.removeItem(
      MAGENTA_CALCULATOR_CONFIG_TRANSFER_LOCAL_STORAGE_KEY
    );
  }, [configurationsStore, productsStore, addPromotion]);

  return { applyMagentaCalculatorConfig };
};
