// extracted by mini-css-extract-plugin
var _1 = "extra-selection-module-active-N06Q0";
var _2 = "extra-selection-module-currentPrice-NwrPa";
var _3 = "extra-selection-module-descriptionWrapper-TJnlu";
var _4 = "extra-selection-module-disabled-ita3y";
var _5 = "extra-selection-module-extraInputWrapper-DpPQg";
var _6 = "extra-selection-module-extraSelection-eVNXQ";
var _7 = "extra-selection-module-extraSelectionLabelWrapper-Jd0xg";
var _8 = "extra-selection-module-extraTitle-naTvd";
var _9 = "extra-selection-module-extraTitleWrapper-CX3nF";
var _a = "extra-selection-module-oldPrice-zYaXJ";
var _b = "extra-selection-module-promotionIcon-WIffG";
var _c = "extra-selection-module-promotionIconWrapper-pN2mt";
export { _1 as "active", _2 as "currentPrice", _3 as "descriptionWrapper", _4 as "disabled", _5 as "extraInputWrapper", _6 as "extraSelection", _7 as "extraSelectionLabelWrapper", _8 as "extraTitle", _9 as "extraTitleWrapper", _a as "oldPrice", _b as "promotionIcon", _c as "promotionIconWrapper" }
