import {
  ICartItemData,
  ICartItemDataPromotions,
} from "../core/entities/PencilSelling/CartItem/ICartItem";
import { PromotionPriceType } from "../core/entities/Product/IDiscount";
import { CustomPromotionTypes } from "../core/entities/PencilSelling/ICustomPromotion";

export const getCashbackPromotions = (
  cartItems: ICartItemData[]
): ICartItemDataPromotions =>
  cartItems
    .map((cartItem) => cartItem.promotions)
    .flat()
    .filter(
      (promotion) =>
        promotion.discount.kind === PromotionPriceType.CASHBACK ||
        ("type" in promotion &&
          promotion.type === CustomPromotionTypes.PROMOTION_TYPE_CASHBACK)
    );
