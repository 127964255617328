export const PDF_REQUEST_DATA = {
  domain: "business",
  pdf_layout: "new-design",
};

export const getOfferLinkEmailText = (offerLink: string) =>
  `
Sehr geehrte Kundin, sehr geehrter Kunde,
vielen Dank für Ihr Interesse! Wir freuen uns, Ihnen Ihr persönliches Angebot zusenden zu können, das genau auf Ihre Wünsche und Bedürfnisse 
abgestimmt ist. 
Hier finden Sie Ihr persönliches Angebot als PDF: ${offerLink}
Der Link ist 14 Tage lang gültig.
Haben Sie Fragen oder wünschen Sie weitere Informationen? Unser Team steht Ihnen jederzeit gerne zur Verfügung.
Wir freuen uns darauf, Sie bald wieder bei uns begrüßen zu dürfen!
Mit freundlichen Grüßen
`.trim();