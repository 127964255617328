import React, { useState } from "react";
import { observer } from "mobx-react";
import { Tile, CopyText, Heading, Icon } from "@dtpk-cc/components";
import AddPromotionIcon from "../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/custom-promotion-add.svg";
import TrashCanIcon from "../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/trash-can.svg";
import * as styles from "./custom-promotions-section.module.scss";
import { BaseOffer } from "../../../../core/entities/PencilSelling/BaseOffer/BaseOffer";
import { ProductsRepository } from "../../../../core/repositories/ProductsRepository/ProductsRepository";
import { useAddTariffPromotionToOffer } from "../../../../hooks/PencilSelling/useAddTariffPromotionToOffer";
import { ICustomPromotion } from "../../../../core/entities/PencilSelling/ICustomPromotion";
import { PortfolioKeys } from "../../../../core/entities/Product/IProduct";
import { ICustomPromotionsForm } from "../IPromotionSelection";
import { ICartItemDataPromotions } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";
import CustomPromotionsForm from "../CustomPromotionsForm";
import { trackClick } from "../../../../helpers/reactTracking";
import CustomPromotionsActionBlock from "../CustomPromotionsActionBlock";

type CustomPromotionsSectionProps = {
  offerStore: BaseOffer;
  productsRepositoryStore: ProductsRepository;
  portfolioKey: PortfolioKeys;
  isAddMode: boolean;
  setIsAddMode: React.Dispatch<React.SetStateAction<boolean>>;
  isFormVisible: boolean;
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formState: ICustomPromotionsForm;
  setFormState: React.Dispatch<React.SetStateAction<ICustomPromotionsForm>>;
  modalPromotionsState: ICartItemDataPromotions;
  setModalPromotionsState: React.Dispatch<
    React.SetStateAction<ICartItemDataPromotions>
  >;
  addPromotionsHandler: (promotions: ICartItemDataPromotions) => void;
  availablePromotions: ICartItemDataPromotions;
  selectedPromotions: ICartItemDataPromotions;
  defaultFormConfig: ICustomPromotionsForm;
};

const TRACKING_CONTEXT = "individuelle Werbung";

const CustomPromotionsSection = ({
  offerStore,
  productsRepositoryStore,
  portfolioKey,
  isAddMode,
  setIsAddMode,
  isFormVisible,
  setIsFormVisible,
  formState,
  setFormState,
  modalPromotionsState,
  setModalPromotionsState,
  addPromotionsHandler,
  availablePromotions,
  selectedPromotions,
  defaultFormConfig,
}: CustomPromotionsSectionProps) => {
  const { addPromotion } = useAddTariffPromotionToOffer(offerStore);
  const { setIsLoading } = offerStore;
  const [deleteConfirmModalIsOpen, setDeleteConfirmModalIsOpen] =
    useState(false);

  const editCustomPromotionHandler = async (promotion: ICustomPromotion) => {
    try {
      setIsLoading(true);
      const promotionIsInModalState = modalPromotionsState.some(
        (promotionItem) => promotionItem.key === promotion.key
      );

      if (promotionIsInModalState) {
        const updatePromotion = (promotionItems: ICartItemDataPromotions) =>
          promotionItems.map((selectedPromotion) =>
            selectedPromotion.key === promotion.key
              ? promotion
              : selectedPromotion
          );
        // Replace existing selected promotion data in cart
        addPromotionsHandler(updatePromotion(selectedPromotions));
        // Replace existing highlighted promotion data in local state
        setModalPromotionsState((prevSelectedPromotions) =>
          updatePromotion(prevSelectedPromotions)
        );
      }

      const customPromotion = await productsRepositoryStore.editCustomPromotion(
        promotion
      );
      offerStore.replaceCustomPromotion(
        customPromotion.portfolio,
        customPromotion
      );
      setFormState(defaultFormConfig);
      setIsFormVisible(false);
      setIsAddMode(false);
      trackClick("benutzerdefinierte-werbung-bearbeiten");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addCustomPromotionHandler = async (promotionItem: ICustomPromotion) => {
    try {
      setIsLoading(true);
      const customPromotion = await productsRepositoryStore.postCustomPromotion(
        promotionItem
      );
      addPromotion(customPromotion, portfolioKey);
      setFormState(defaultFormConfig);
      setIsFormVisible(false);
      setIsAddMode(false);
      trackClick("fügen-sie-benutzerdefinierte-werbung-hinzu");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      console.log(e?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const removeCustomPromotionHandler = async () => {
    try {
      setIsLoading(true);
      const filterActivePromotions = (
        promotionItems: ICartItemDataPromotions
      ) => {
        const promotionExist = promotionItems.some(
          (promotionItem) => promotionItem.key === formState.key
        );

        if (promotionExist) {
          return promotionItems.filter(
            (promotionItem) => promotionItem.key !== formState.key
          );
        }
        return promotionItems;
      };
      await productsRepositoryStore.deleteCustomPromotion(formState.key);
      setDeleteConfirmModalIsOpen(false);
      setIsFormVisible(false);
      offerStore.removeCustomPromotion(portfolioKey, formState.key);
      // Replace existing selected promotion data in cart
      addPromotionsHandler(filterActivePromotions(selectedPromotions));
      // Replace existing highlighted promotion data in local state
      setModalPromotionsState(filterActivePromotions(modalPromotionsState));
      setFormState(defaultFormConfig);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`m-l-12 ${styles.wrapper}`}>
      {!isFormVisible && !deleteConfirmModalIsOpen && (
        <Tile
          customClass={styles.addBtn}
          tabIndex={0}
          onClick={() => {
            setIsFormVisible(true);
            setIsAddMode(true);
          }}
          variant="shadow"
        >
          <AddPromotionIcon className={styles.addBtnIcon} />
          <CopyText
            customClass={`m-t-12 m-b-0 ${styles.addBtnText}`}
            variants={CopyText.Variant.highlight}
          >
            Eigene Aktion hinzufügen
          </CopyText>
        </Tile>
      )}
      {isFormVisible && (
        <CustomPromotionsForm
          formState={formState}
          defaultFormConfig={defaultFormConfig}
          portfolioKey={portfolioKey}
          setFormState={setFormState}
          modalPromotionsState={modalPromotionsState}
          availablePromotions={availablePromotions}
          isAddMode={isAddMode}
          setIsFormVisible={setIsFormVisible}
          setDeleteConfirmModalIsOpen={setDeleteConfirmModalIsOpen}
          trackingContext={TRACKING_CONTEXT}
          onAdd={addCustomPromotionHandler}
          onEdit={editCustomPromotionHandler}
        />
      )}
      {/* Remove confirm dialog  */}
      {deleteConfirmModalIsOpen && (
        <Tile variant="shadow">
          <Heading
            customClass="m-b-36"
            variants={[Heading.Variant.quaternary, Heading.Variant.highlight]}
          >
            Eigene Aktion löschen
          </Heading>
          <div className="m-b-36">
            <CopyText
              variants={CopyText.Variant.highlight}
              customClass="m-b-36"
            >
              Bist Du sicher, dass Du diese Aktion löschen willst?
            </CopyText>
            <Icon customClass={styles.removeIcon} icon={TrashCanIcon} />
          </div>
          <CustomPromotionsActionBlock
            cancelBtnHandler={() => {
              setIsFormVisible(true);
              setDeleteConfirmModalIsOpen(false);
            }}
            submitBtnHandler={() => {
              removeCustomPromotionHandler().catch(() => {
                //  Do nothing...
              });
              trackClick("entfernen", TRACKING_CONTEXT);
            }}
            isAddMode={isAddMode}
          />
        </Tile>
      )}
    </div>
  );
};

export default observer(CustomPromotionsSection);
