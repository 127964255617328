import React from "react";
import { Price } from "@dtpk-cc/components";
import * as styles from "./summary-configuration-main-price-wrapper.module.scss";

type SummaryConfigurationMainPriceWrapperProps = {
  stattPrice?: number | null;
  customClass?: string;
  children?: React.ReactNode;
};

const SummaryConfigurationMainPriceWrapper = ({
  stattPrice = null,
  customClass = "",
  children,
}: SummaryConfigurationMainPriceWrapperProps) => (
  <div className={`${styles.mainPriceWrapper} ${customClass}`}>
    {stattPrice && (
      <div className="m-r-6">
        <Price value={stattPrice} variants={Price.Variant.scaled} />
      </div>
    )}
    {children}
  </div>
);

export default SummaryConfigurationMainPriceWrapper;
