import React from "react";
import { Button, Icon } from "@dtpk-cc/components";
import PdfIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/pdf.svg";
import QrCodeIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/qr-code.svg";
import CopyLinkIcon from "../../../../../assets/images/icons/pencil-selling-tool/summary/copy-link.svg";

import * as styles from "./summary-pdf-buttons.module.scss";

type SummaryPDFButtonsProps = {
  getOfferPdf: () => void;
  getPdfQRCode: () => void;
  getOfferPdfLink: () => void;
};

const SummaryPDFButtons = ({
  getOfferPdf,
  getPdfQRCode,
  getOfferPdfLink,
}: SummaryPDFButtonsProps) => (
  <div className={styles.buttonsWrapper}>
    <Button
      tabIndex={0}
      onClick={getPdfQRCode}
      variants={Button.Variant.icon}
      customClass={`${styles.btn} ${styles.qrCodeBtn}`}
    >
      <Icon wrapperProps={{ className: styles.btnIcon }} icon={QrCodeIcon} />
      QR Code
    </Button>
    <Button
      tabIndex={0}
      onClick={getOfferPdfLink}
      variants={Button.Variant.icon}
      customClass={`${styles.btn} default-button blue`}
    >
      <Icon wrapperProps={{ className: styles.btnIcon }} icon={CopyLinkIcon} />
      Link kopieren
    </Button>
    <Button
      tabIndex={0}
      onClick={getOfferPdf}
      variants={Button.Variant.icon}
      customClass={`${styles.btn}`}
    >
      <Icon wrapperProps={{ className: styles.btnIcon }} icon={PdfIcon} />
      PDF drucken
    </Button>
  </div>
);

export default SummaryPDFButtons;
