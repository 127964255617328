import React from "react";
import { toast } from "react-toastify";
import { CopyText } from "@dtpk-cc/components";

export enum ToasterTypes {
  SUCCESS = "success",
  ERROR = "error",
  DEFAULT = "default",
  INFO = "info",
}

export const useToaster = () => ({
  success: (text: string) => {
    toast.success(() => <CopyText>{text}</CopyText>);
    toast.clearWaitingQueue();
  },
  error: (text: string) => {
    toast.error(() => <CopyText>{text}</CopyText>);
    toast.clearWaitingQueue();
  },
  default: (text: string) => {
    toast(() => <CopyText>{text}</CopyText>);
    toast.clearWaitingQueue();
  },
  info: (text: string) => {
    toast.info(() => <CopyText>{text}</CopyText>);
    toast.clearWaitingQueue();
  },
});
