import React from "react";
import { Price } from "@dtpk-cc/components";
import * as styles from "./tariff-calculated-monthly-price.module.scss";
import SummaryConfigurationMainPriceWrapper from "../../components/new-design/Summary/SummaryConfigurationMainPriceWrapper";

type TariffCalculatedMonthlyPriceProps = {
  priceFontSizeIsLarge?: boolean;
  withMonthlySuffix?: boolean;
  monthlyPrice?: number | null;
  priceStepsDescriptions?: string[];
  monthlyPriceSuffix?: string | null;
  prefix?: string;
  isBold?: boolean;
  stattPriceDescr?: number | null;
};

const TariffCalculatedMonthlyPrice = ({
  priceFontSizeIsLarge = true,
  withMonthlySuffix = false,
  monthlyPrice,
  priceStepsDescriptions = [],
  monthlyPriceSuffix,
  prefix = "",
  isBold = false,
  stattPriceDescr = null,
}: TariffCalculatedMonthlyPriceProps) => {
  const suffix = [
    withMonthlySuffix ? " mtl." : monthlyPriceSuffix,
    monthlyPrice > 0 ? prefix : "",
  ].join(" ");
  const priceVariants = priceFontSizeIsLarge
    ? [Price.Variant.brand, Price.Variant.large]
    : [Price.Variant.brand];

  if (isBold) {
    priceVariants.push(Price.Variant.highlight);
  }
  return (
    (typeof monthlyPrice === "number" && (
      <>
        <SummaryConfigurationMainPriceWrapper
          stattPrice={stattPriceDescr}
          customClass={styles.mainPriceWrapper}
        >
          <Price
            variants={priceVariants}
            value={monthlyPrice}
            suffix={suffix}
          />
        </SummaryConfigurationMainPriceWrapper>
        {priceStepsDescriptions.map((priceDescription) => (
          <Price.Text
            key={priceDescription}
            dangerouslySetInnerHTML={{ __html: priceDescription }}
          />
        ))}
      </>
    )) ||
    null
  );
};

export default TariffCalculatedMonthlyPrice;
