import {
  IBannerLinks,
  IDistributionData,
  OfferSummaryBasePresenter,
} from "../OfferSummaryBasePresenter";
import { IBaseOfferExport } from "../../../entities/PencilSelling/BaseOffer/IBaseOffer";
// TODO Temporary not used.
export class PrivateClientOfferSummaryPresenter extends OfferSummaryBasePresenter {
  constructor(
    private offerExport: IBaseOfferExport,
    protected bannerLinksData: IBannerLinks,
    protected baseDistributionData: IDistributionData,
    protected mobilePortfolioAveragePrice: number | null,
  ) {
    super(
      bannerLinksData,
      baseDistributionData,
      offerExport.details,
      offerExport.customerNote,
      offerExport.displayConfigurationTotalPrice,
      offerExport.displayOfferTotalPrice,
      mobilePortfolioAveragePrice
    );
    this.offerExport = offerExport;
  }

  export() {
    return super.export();
  }
}
