import { makeAutoObservable } from "mobx";
import { ConfigurationsEntity } from "../../Configurations/Configurations";
import { BaseOffer } from "../../BaseOffer/BaseOffer";
import { IOffer } from "./IOffer";

export class Offer implements IOffer {
  configurationsEntity: ConfigurationsEntity;

  baseOffer: BaseOffer;

  constructor(configurationsEntity: ConfigurationsEntity) {
    this.configurationsEntity = configurationsEntity;
    this.baseOffer = new BaseOffer(configurationsEntity);
    makeAutoObservable(this);
  }

  getBaseOffer(): BaseOffer {
    return this.baseOffer;
  }

  export() {
    return this.baseOffer.export();
  }
}
