import React from "react";
import { CopyText } from "@dtpk-cc/components";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores/PrivateClient";
import PageWrapper from "../../../../elements/new-design/PageWrapper";
import StepHeader from "../../../../components/new-design/StepHeader";
import PersonalPageInner from "../../../../components/new-design/Personal/PersonalPageInner";
import UserNote from "../../../../components/new-design/Personal/UserNote";
import BusinessCases from "../../../../components/new-design/Personal/BusinessCases";
import { ICartConfigurationType } from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { Cart } from "../../../../core/entities/PencilSelling/Cart/Cart";
import TileContainer from "../../../../components/new-design/TileContainer";
import { TileContainerVariants } from "../../../../components/new-design/TileContainer/ITileContainer";
import NotifyHintBox from "../../../../elements/new-design/NotifyHintBox";
import * as styles from "./personal.module.scss";
import { NotifyHintBoxBgVariants } from "../../../../elements/new-design/NotifyHintBox/INotifyHintBox";

const trackingContext = "basisdaten";

type PersonalProps = {
  addMobileConfigurationHandler: () => void;
};
const Personal = ({ addMobileConfigurationHandler }: PersonalProps) => {
  const { offerStore, configurationsStore } = useStores();

  const onConfigurationsReset = (type: ICartConfigurationType) => {
    if (type === ICartConfigurationType.MOBILE) {
      configurationsStore.resetConfigurationsByType(type);
      configurationsStore.resetConfigurationsByType(
        ICartConfigurationType.CARD
      );
      addMobileConfigurationHandler();
      return;
    }

    if (type === ICartConfigurationType.CARD) {
      const cardsConfigurations =
        configurationsStore.getConfigurationsByType(type);
      cardsConfigurations.forEach((cardConfiguration) => {
        cardConfiguration.children = new Cart();
      });
      return;
    }

    configurationsStore.resetConfigurationsByType(type);
  };

  return (
    <PageWrapper>
      <StepHeader title="Kundenangaben" subtitle="Basisdaten des Kunden" />
      <PersonalPageInner>
        <div className="mainContent">
          <TileContainer
            customClass="dataContainer"
            variant={TileContainerVariants.outline}
          >
            <NotifyHintBox
              variant={NotifyHintBoxBgVariants.blue}
              customClass={styles.hintBox}
            >
              <CopyText>
                Wähle den Geschäftsfall aus, um passende Aktionen und Produkte
                auszuwählen.
              </CopyText>
            </NotifyHintBox>
            <BusinessCases
              offerStore={offerStore.getBaseOffer()}
              configurationsStore={configurationsStore}
              onConfigurationsReset={onConfigurationsReset}
              trackingContext={trackingContext}
            />
          </TileContainer>
        </div>
        <UserNote
          offerStore={offerStore.getBaseOffer()}
          trackingContext={trackingContext}
        />
      </PersonalPageInner>
    </PageWrapper>
  );
};

export default observer(Personal);
