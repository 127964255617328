import React from "react";
import {
  Grid,
  Price,
  Badge,
  CopyText,
  Icon,
  Button,
} from "@dtpk-cc/components";

import * as stylesExtra from "../extra-selection.module.scss";
import * as styles from "./promotion-selection-item.module.scss";
import CheckboxInput from "../../CheckboxInput";
import {
  IDiscount,
  PromotionPriceType,
} from "../../../../core/entities/Product/IDiscount";
import EditConfigurationIcon from "../../../../../assets/images/icons/pencil-selling-tool/tariff-selection/edit.svg";
import { convertPriceStringToNumber } from "../../../../helpers/NumberHelpers";
import { CustomPromotionTypes } from "../../../../core/entities/PencilSelling/ICustomPromotion";

type PromotionSelectionItemProps = {
  value?: string;
  name: string;
  id: string;
  inputTestId?: string;
  checked: boolean;
  onChange: (value) => void;
  title: string;
  subtitle: string | null;
  pricing: IDiscount;
  productPrice: number | undefined;
  onEdit?: () => void | null;
  disabled?: boolean;
  customType?: CustomPromotionTypes | null;
};

const PromotionSelectionItem = ({
  value = "",
  name,
  id,
  inputTestId = null,
  checked,
  onChange,
  title,
  subtitle,
  pricing,
  productPrice,
  customType = null,
  onEdit = null,
  disabled = false,
}: PromotionSelectionItemProps) => {
  const isMonthly =
    pricing.kind === PromotionPriceType.MONTHLY ||
    pricing.kind === PromotionPriceType.MONTHLY_DISCOUNT ||
    pricing.kind === PromotionPriceType.PERMANENT_MONTHLY_DISCOUNT;

  const isPercentage =
    pricing.kind === PromotionPriceType.MONTHLY_DISCOUNT_IN_PERCENT ||
    pricing.kind === PromotionPriceType.DISCOUNT_IN_PERCENT ||
    pricing.kind === PromotionPriceType.PROVISION_DISCOUNT_IN_PERCENT;

  const priceSuffix = isMonthly || pricing.interval > 0 ? "mtl." : "einm.";

  const isCustom = pricing.kind === PromotionPriceType.CUSTOM_PROMOTION;

  let customPromotionTitlePrefix = "";

  if (isCustom && pricing.interval) {
    customPromotionTitlePrefix =
      pricing.interval > 1
        ? `${pricing.interval} Monate`
        : `${pricing.interval} Monat`;
  }

  const priceAfterPromotion = () => {
    if (isCustom && customType !== CustomPromotionTypes.PROMOTION_TYPE_CASHBACK) {
      return pricing.value
        ? -convertPriceStringToNumber(`${pricing.value}`)
        : null;
    }
    return pricing.kind === PromotionPriceType.MONTHLY_DISCOUNT
      ? productPrice - pricing.value
      : pricing.value;
  };

  return (
    <label
      aria-disabled={disabled}
      className={`${stylesExtra.extraSelectionLabelWrapper} ${
        disabled ? stylesExtra.disabled : ""
      } ${styles.wrapper}`}
      htmlFor={id}
    >
      {isCustom && (
        <Badge
          innerCustomClass={styles.customPromotionBadge}
          variants={Badge.Variant.flag}
        >
          <CopyText
            tag="span"
            variants={CopyText.Variant.highlight}
            customClass={styles.customPromotionBadgeText}
          >
            Eigene Aktion
          </CopyText>
        </Badge>
      )}
      <Grid.Row
        customClass={`${styles.row} ${stylesExtra.extraSelection} ${
          checked ? stylesExtra.active : ""
        }`}
      >
        <Grid.Col
          customClass={`${stylesExtra.extraInputWrapper} ${styles.checkBoxWrapper}`}
          m={1}
          l={1}
          xl={1}
        >
          <CheckboxInput
            disabled={disabled}
            value={value}
            name={name}
            id={id}
            inputTestId={inputTestId}
            checked={checked}
            onChange={onChange}
          />
        </Grid.Col>
        <Grid.Col
          customClass={`${stylesExtra.extraTitleWrapper} p-l-6`}
          xl={14}
          l={7}
          m={7}
        >
          <CopyText
            customClass={stylesExtra.extraTitle}
          >{`${customPromotionTitlePrefix} ${title}`}</CopyText>
          {subtitle && (
            <CopyText variants={CopyText.Variant.additional}>
              {subtitle}
            </CopyText>
          )}
        </Grid.Col>
        <Grid.Col customClass={`${stylesExtra.oldPrice}`} m={2} l={2} xl={4}>
          {isMonthly && productPrice && (
            <Price
              value={productPrice}
              prefix="statt"
              variants={[Price.Variant.scaled, Price.Variant.large]}
            />
          )}
        </Grid.Col>
        <Grid.Col
          customClass={`${styles.currentPriceWrapper} ${stylesExtra.currentPrice}`}
          m={2}
          l={2}
          xl={5}
        >
          {typeof priceAfterPromotion() === "number" && !isPercentage && (
            <Price
              value={priceAfterPromotion()}
              suffix={priceSuffix}
              // Check for adding blue title when value is negative
              variants={[Price.Variant.brand, Price.Variant.large]}
            />
          )}
          {/*  TODO Refactor */}
          {isPercentage && typeof pricing.value === "number" && (
            <CopyText
              customClass={styles.percentageValue}
              variants={CopyText.Variant.magenta}
            >
              <span className="m-r-4">-</span>
              {pricing.value}
              <span className="m-l-6">&#37;</span>
            </CopyText>
          )}
          {onEdit && (
            <Button
              customClass={styles.editBtn}
              variants={Button.Variant.bare}
              onClick={onEdit}
            >
              <Icon
                wrapperProps={{ className: styles.editIcon }}
                icon={EditConfigurationIcon}
              />
            </Button>
          )}
        </Grid.Col>
      </Grid.Row>
    </label>
  );
};

export default PromotionSelectionItem;
