import { CalculatorProductsResponse } from "./CalculatorResponseInterface";
import {
  CustomAddonsRequestKeys,
  CustomPromotionFetchResponse,
  ProductsResponse,
  StreamingAnalysisResponse,
} from "./ResponseInterface";
import { ICalculatorExport } from "../../presenter/MagentaNext/MagentaNextSummaryPresenter";
import { ICustomPromotion } from "../../entities/PencilSelling/ICustomPromotion";
import { IOfferSummary } from "../../presenter/PencilSelling/OfferSummaryPresenter";
import { IOfferConfigurationsSummary } from "../../../components/new-design/Summary/ISummary";
import { ICustomAddon } from "../../../components/new-design/AddonsSelection/IAddonsSelection";
import { DomainType } from "../../entities/PencilSelling/Customer/ICustomer";

export enum Endpoints {
  pencil_selling = "/api/tools/pencil_selling/personal",
  pencil_selling_business = "/api/tools/pencil_selling/business",
  pencil_selling_employee = "/api/tools/pencil_selling/employee",
  magenta_calculator = "/api/tools/magenta_calculator",
  magenta_calculator_pdf = "/api/tools/magenta_calculator/pdf.json",
  pdf = "/api/tools/pencil_selling/pdf.json",
  pdf_qr = "/api/tools/pencil_selling/pdf_with_qr_code.json",
  pdf_link = "/api/tools/pencil_selling/pdf_link.json",
  pdf_qr_calculator = "/api/tools/magenta_calculator/pdf_with_qr_code.json",
  streaming_analysis = "/api/tools/pencil_selling/streaming_analysis",
  fetch_custom_promotions = "/api/tools/pencil_selling/custom_promotions.json?tool_domain=",
  handle_custom_promotion = "/api/tools/pencil_selling/custom_promotions",
  fetch_custom_devices = "/api/tools/pencil_selling/custom_devices.json?tool_domain=",
  fetch_custom_addons = "/api/tools/pencil_selling/custom_addons.json?tool_domain=",
  handle_custom_addons = "/api/tools/pencil_selling/custom_addons",
  handle_custom_devices = "/api/tools/pencil_selling/custom_devices",
  logout = "/api/sessions",
  charge_privilege_address_check = "/api/tools/service_charge_privilege/address_search?query=",
  fiber_address_check = "/api/tools/fiber_address_search?query=",
}

export interface IProductsRepository {
  getProducts(url: Endpoints): Promise<ProductsResponse | null>;
  getSreamingAnalysis(): Promise<StreamingAnalysisResponse | null>;
  getCalculatorProducts(): Promise<CalculatorProductsResponse | null>;
  submitOrder(
    data: IOfferSummary | IOfferConfigurationsSummary,
    email?: string
  ): Promise<string>;
  getOfferPdfLink(
    data: IOfferSummary | IOfferConfigurationsSummary,
  ): Promise<string>;
  submitPdfQrCode(
    data: IOfferSummary | IOfferConfigurationsSummary
  ): Promise<Response>;
  submitCalculatorPdfQrCode(data: ICalculatorExport): Promise<Response>;
  getCalculatorPDF(data: ICalculatorExport): Promise<string>;
  setCustomPromotion(
    customPromotion: ICustomPromotion,
    method: string,
    endpoint: string
  ): Promise<ICustomPromotion>;
  postCustomPromotion(data: ICustomPromotion): Promise<ICustomPromotion>;
  editCustomPromotion(data: ICustomPromotion): Promise<ICustomPromotion>;
  deleteCustomPromotion(promotionKey: string): Promise<Response>;
  fetchCustomPromotion(): Promise<CustomPromotionFetchResponse>;
  fetchCustomAddons(
    endpoint: string,
    domainType: DomainType
  ): Promise<ICustomAddon[]>;
  createCustomAddon(
    endpoint: string,
    domainType: DomainType,
    data: ICustomAddon,
    customAddonTypeKey: CustomAddonsRequestKeys
  ): Promise<ICustomAddon>;
  deleteCustomAddon(
    endpoint: string,
    domainType: DomainType,
    customAddonKey: string
  ): Promise<Response>;
  logout(): Promise<Response>;
}
