import React from "react";
import { Grid, Button, A11yText, CopyText } from "@dtpk-cc/components";
import { ActionRemoveDefault } from "@dtpk-cc/components/dist/icons";
import { onlyNumber } from "helpers/NumericCalculation";
import Input from "../../../../../../elements/new-design/Input";

import * as styles from "./phone-item.module.scss";
import DropdownMain from "../../../../../../elements/new-design/DropdownMain";
import { SMARTPHONES_MANUFACTURERS } from "../../../../../../constants/const";
import CheckboxInput from "../../../../../../elements/new-design/CheckboxInput";
import { IStepTrackingContext } from "../../../../../../views/PencilSelling/IPencilSelling";
import { trackClick } from "../../../../../../helpers/reactTracking";

type PhoneItemSelectionProps = {
  name: string;
  stattPrice: string;
  price: string;
  onNameChange: (value) => void;
  onManufacturerChange: (value) => void;
  onNameClick: () => void;
  onPriceChange: (value) => void;
  onPriceClick: () => void;
  onIsAlternativeChange: () => void;
  onRemove: () => void;
  index: number;
  manufacturer: string;
  isAlternative: boolean;
  customClass?: string;
  trackingContext: IStepTrackingContext;
  onChange: (index: number, key: string, value: string | boolean) => void;
};

const PhoneItem = ({
  name,
  price,
  onRemove,
  onNameClick,
  index,
  manufacturer,
  isAlternative,
  trackingContext,
  onChange,
  stattPrice,
}: PhoneItemSelectionProps) => (
  <Grid.Row customClass={styles.phoneItemSelection}>
    <Grid.Col customClass={styles.removeCheckBoxWrapper} m={1} l={1} xl={2}>
      <label
        className={`${styles.isAlternativeCheck} ${
          index === 0 ? styles.disabled : ""
        }`}
        htmlFor={`is-alternative-${index}`}
      >
        <CheckboxInput
          inactive={index === 0}
          name={`is-alternative-${name}`}
          id={`is-alternative-${index}`}
          checked={isAlternative}
          onChange={() => {
            onChange(index, "isAlternative", !isAlternative);
            trackClick(
              `${!isAlternative ? "alternative" : "regulär"}.smartphone`,
              trackingContext.main
            );
          }}
        />
      </label>
    </Grid.Col>
    <Grid.Col
      customClass={`${styles.dropdownWrapper} p-r-12`}
      m={3}
      l={3}
      xl={7}
    >
      {isAlternative && (
        <CopyText
          customClass={styles.phoneItemSubtitle}
          variants={[CopyText.Variant.additional, CopyText.Variant.highlight]}
        >
          Alternative
        </CopyText>
      )}
      <DropdownMain
        label="Herstellername"
        items={SMARTPHONES_MANUFACTURERS}
        onSelection={(value) => {
          onChange(index, "manufacturer", value);
          trackClick(`${value}.smartphone.manufacturer`, trackingContext.main);
        }}
        value={manufacturer}
      />
    </Grid.Col>
    <Grid.Col customClass={"p-r-12"} m={3} l={3} xl={6}>
      <Input
        id={`phone-name-${index}`}
        value={name}
        onClick={onNameClick}
        onChange={({ target: { value } }) => {
          onChange(index, "name", value.trim());
          trackClick(`${value}.smartphone.name`, trackingContext.main);
        }}
        placeholder="Endgerätename"
      />
    </Grid.Col>
    <Grid.Col customClass={"p-r-12"} m={2} l={2} xl={3}>
      <Input
        id={`phone-statt-price-${index}`}
        value={stattPrice}
        onChange={({ target: { value } }) =>
          onChange(index, "stattPrice", value)
        }
        onClick={() =>
          trackClick(
            `${trackingContext.portfolio}.smartphone.uvp.preis`,
            trackingContext.main
          )
        }
        onKeyPress={(e) => onlyNumber(e)}
        placeholder="UVP"
      />
    </Grid.Col>
    <Grid.Col customClass={"p-r-12"} m={2} l={2} xl={4}>
      <Input
        id={`phone-price-${index}`}
        value={price}
        onChange={({ target: { value } }) => onChange(index, "price", value)}
        onClick={() =>
          trackClick(
            `${trackingContext.portfolio}.smartphone.aktionspreis`,
            trackingContext.main
          )
        }
        onKeyPress={(e) => onlyNumber(e)}
        placeholder="Aktionspreis"
      />
    </Grid.Col>
    <Grid.Col customClass={styles.removeBtnWrapper} m={1} l={1} xl={2}>
      <Button
        tabIndex={0}
        customClass={styles.removeBtn}
        onClick={onRemove}
        variants={[Button.Variant.bare, Button.Variant.icon]}
      >
        <Button.Icon icon={ActionRemoveDefault} />
        <A11yText>Smartphone-Element entfernen</A11yText>
      </Button>
    </Grid.Col>
  </Grid.Row>
);

export default PhoneItem;
