import React from "react";
import { TextLink, Heading } from "@dtpk-cc/components";
import ModalWrapper from "../../../../elements/new-design/ModalWrapper";
import ModalWrapperActionFooter from "../../../../elements/new-design/ModalWrapper/ModalWrapperActionFooter";

type OfferLinkModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  offerLink: string;
  customClass?: string;
};

const OfferLinkModal = ({
  customClass = "",
  isOpen,
  onCancel,
  onSubmit,
  offerLink,
}: OfferLinkModalProps) => (
  <ModalWrapper
    containerCustomClass={customClass}
    isOpen={isOpen}
    onClose={onCancel}
  >
    <Heading variants={Heading.Variant.tertiary}>
      In die Zwischenablage kopieren
    </Heading>
    <TextLink target="_blank" href={offerLink}>
      {offerLink}
    </TextLink>
    <ModalWrapperActionFooter submitBtnText="Kopieren" onSubmit={onSubmit} onCancel={onCancel} />
  </ModalWrapper>
);

export default OfferLinkModal;
