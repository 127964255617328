import { CartItemSummaryPresenter } from "../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import {
  ICartConfiguration,
  ICartConfigurationType,
  ILandlineSettings,
} from "../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { Group } from "../../../../core/entities/Product/IProduct";
import { IConfigurationsSummary } from "../ISummary";
import {
  calculateConfigurationItemOnceTotalPrice,
  getConfigurationItemTotalMonthlyPrice,
  mapTariffAddonsSummary,
} from "./index";
import { ICartItemData } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";

const mapLandlineSummary = ({
  offerExportConfigurations,
  configurationsSummary,
  priceType,
}: {
  offerExportConfigurations: ICartConfiguration[];
  configurationsSummary: IConfigurationsSummary;
  priceType: string;
}) => {
  const filteredOfferExportConfigurations = offerExportConfigurations.filter(
    (offerExportedConfigurationItem) =>
      !!offerExportedConfigurationItem.children.getLandlineTariff() ||
      !!offerExportedConfigurationItem.children.getLandlineOptions().length
  );

  filteredOfferExportConfigurations.forEach((configurationItem, index) => {
    const summaryConfigurationsArr =
      configurationsSummary[ICartConfigurationType.LANDLINE].configurationItems;
    const landlineTariff = configurationItem.children.getLandlineTariff();
    const landlineAddons = configurationItem.children.getLandlineOptions();
    const configurationCartItems: ICartItemData[] = [];

    summaryConfigurationsArr.push({
      title: configurationItem.name
        ? configurationItem.name
        : `Festnetz-Konfiguration Nr. ${index + 1}`,
      amount: configurationItem.amount,
      sections: [],
      totalPrice: {
        priceType,
        title: "Teilsumme",
        monthlyPrice: {
          mainPrice: null,
          priceStepsDescriptions: [],
        },
        oncePrice: null,
      },
    });

    if (landlineTariff) {
      const configurationSettings = configurationItem.data
        .settings as ILandlineSettings;
      const presenter = new CartItemSummaryPresenter(
        landlineTariff,
        configurationSettings,
        priceType
      );
      const tariffBenefit = configurationItem.data.isBenefitActive
        ? configurationItem.data.benefit
        : null;

      const tariffSectionItem = {
        name: "Festnetz Tarif",
        group: Group.tariff,
        items: [
          presenter.configurationExport(
            tariffBenefit,
            configurationItem.data.note
          ),
        ],
      };

      configurationCartItems.push(landlineTariff);

      summaryConfigurationsArr[index].sections.push(tariffSectionItem);
    }

    if (landlineAddons.length) {
      // Map Tariff Addons
      mapTariffAddonsSummary(
        configurationItem,
        configurationsSummary,
        landlineAddons,
        ICartConfigurationType.LANDLINE,
        index
      );
    }

    summaryConfigurationsArr[index].totalPrice.monthlyPrice =
      getConfigurationItemTotalMonthlyPrice(summaryConfigurationsArr[index]);

    summaryConfigurationsArr[index].totalPrice.oncePrice =
      calculateConfigurationItemOnceTotalPrice(summaryConfigurationsArr[index]);
  });
};

export default mapLandlineSummary;
