import React from "react";
import { Heading, CopyText, Price } from "@dtpk-cc/components";
import SummarySectionItemWrapper from "../SummarySectionItemWrapper";
import { IMobileAveragePriceData } from "../../../../core/presenter/PencilSelling/OfferSummaryBasePresenter";
import * as styles from "./summary-mobile-average-price-data.module.scss";

const SummaryMobileAveragePriceData = ({
  mobileAveragePriceData,
  customerPricePrefix,
}: {
  mobileAveragePriceData: IMobileAveragePriceData;
  customerPricePrefix: string;
}) => (
  <SummarySectionItemWrapper customClass={styles.wrapper}>
    <SummarySectionItemWrapper.Main>
      <Heading
        tag="p"
        customClass="m-0"
        variants={[Heading.Variant.tertiary]}
      >
        {mobileAveragePriceData.title}
      </Heading>
      <CopyText customClass="m-0">{mobileAveragePriceData.subtitle}</CopyText>
    </SummarySectionItemWrapper.Main>
    <SummarySectionItemWrapper.Monthly>
      <Heading tag="p" variants={[Heading.Variant.quaternary]}>
        Monatlich
      </Heading>
      <Price
        variants={[
          Price.Variant.currentColor,
          Price.Variant.large,
          Price.Variant.highlight,
        ]}
        prefix="&Oslash;"
        value={mobileAveragePriceData.monthlyPrice}
        suffix={customerPricePrefix}
      />
    </SummarySectionItemWrapper.Monthly>
  </SummarySectionItemWrapper>
);

export default SummaryMobileAveragePriceData;
