import React, { useState } from "react";
import { ICartItemData } from "../../../../core/entities/PencilSelling/CartItem/ICartItem";
import AddonsSelectionModalWrapper from "../AddonsSelectionModalWrapper";
import { IAddon } from "../../../../core/presenter/PencilSelling/AddonsPresenter";
import ProductVariantSelectionItem from "../../../../elements/new-design/ExtraSelection/ProductVariantSelectionItem";
import { trackClick } from "../../../../helpers/reactTracking";

type AddonsGroupSelectionModalProps = {
  activeProducts: ICartItemData[];
  groupedProducts: IAddon[];
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (product: IAddon) => void;
  getAdditionalPriceSuffix: (value: IAddon) => string;
  trackingContext: string;
  customClass?: string;
};

const AddonsGroupSelectionModal = ({
  isOpen,
  onCancel,
  onSubmit,
  activeProducts,
  groupedProducts,
  getAdditionalPriceSuffix,
  trackingContext,
  customClass = "",
}: AddonsGroupSelectionModalProps) => {
  const activeProductsKeys = activeProducts.map((item) => item.key);
  const [activeProduct, setActiveProduct] = useState(
    groupedProducts.find((item) => activeProductsKeys.includes(item.key)) ||
      null
  );

  return (
    <AddonsSelectionModalWrapper
      submitDisabled={!activeProduct}
      customClass={customClass}
      title="Produktvariante"
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={() => {
        onSubmit(activeProduct);
        trackClick(`${activeProduct.name}.auswahl.übernehmen`, trackingContext);
      }}
    >
      {groupedProducts.map((product) => (
        <ProductVariantSelectionItem
          key={product.key}
          name={product.productGroup.key}
          id={product.key}
          checked={activeProduct?.key === product.key}
          onChange={() => {
            setActiveProduct(product);
            trackClick(`${product.name}`, trackingContext);
          }}
          title={product.name}
          subtitle={product.additionalDescription}
          price={product.price}
          additionalPriceSuffix={getAdditionalPriceSuffix(product)}
          hasPromotions={!!product.promotions.length}
        />
      ))}
    </AddonsSelectionModalWrapper>
  );
};

export default AddonsGroupSelectionModal;
