import {
  ICartConfiguration,
  ICartConfigurationType,
} from "../../../../../core/entities/PencilSelling/Configurations/IConfigurations";
import { IConfigurationsSummary } from "../../ISummary";
import { CartItemSummaryPresenter } from "../../../../../core/presenter/PencilSelling/CartItemSummaryPresenter";
import { Group } from "../../../../../core/entities/Product/IProduct";
import {
  calculateConfigurationItemOnceTotalPrice,
  getConfigurationItemTotalMonthlyPrice,
} from "../index";
import { IBusinessCasesTypeValues } from "../../../../../core/entities/Product/Tariff/ITariff";

const mapSecondaryCardsSummary = ({
  offerExportConfigurations,
  configurationsSummary,
  priceType,
  businessCase,
}: {
  offerExportConfigurations: ICartConfiguration[];
  configurationsSummary: IConfigurationsSummary;
  priceType: string;
  businessCase: IBusinessCasesTypeValues;
}) => {
  const filteredOfferExportConfigurations = offerExportConfigurations.filter(
    (offerExportedConfigurationItem) =>
      !!offerExportedConfigurationItem.children.getCards().length
  );

  filteredOfferExportConfigurations.forEach((configurationItem, index) => {
    const configurationCards = configurationItem.children.getCards();

    const summaryConfigurationsArr =
      configurationsSummary[ICartConfigurationType.CARD].configurationItems;
    summaryConfigurationsArr.push({
      title: configurationItem.name
        ? configurationItem.name
        : `Zusatzkarten-Konfiguration Nr. ${index + 1}`,
      amount: configurationItem.amount,
      sections: [
        {
          name: "Zusatzkarten",
          group: Group.card,
          items: configurationCards.map((card) => {
            const presenter = new CartItemSummaryPresenter(
              card,
              { businessCase },
              priceType
            );
            return presenter.configurationExport(null, card.cardData.note);
          }),
        },
      ],
      totalPrice: {
        priceType,
        title: "Teilsumme",
        monthlyPrice: {
          mainPrice: null,
          priceStepsDescriptions: [],
        },
        oncePrice: null,
      },
    });

    summaryConfigurationsArr[index].totalPrice.monthlyPrice =
      getConfigurationItemTotalMonthlyPrice(summaryConfigurationsArr[index]);

    summaryConfigurationsArr[index].totalPrice.oncePrice =
      calculateConfigurationItemOnceTotalPrice(summaryConfigurationsArr[index]);
  });
};

export default mapSecondaryCardsSummary;
