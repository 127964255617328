import { ICartItemData } from "core/entities/PencilSelling/CartItem/ICartItem";
import { IBenefit } from "core/entities/Product/IBenefit";
import { TariffWorldKeys } from "core/repositories/ProductsRepository/DefinitionKeys";
import {
  IMobileSettings,
  IOfferExport,
} from "core/entities/PencilSelling/Offer_legacy/IOffer";
import { Group } from "core/entities/Product/IProduct";
import {
  CartItemSummaryPresenter,
  IItemExport,
} from "./CartItemSummaryPresenter";
import { IExtension } from "../../entities/Product/IExtension";
import {
  IBannerLinks,
  IDetailNote,
  IDistributionData,
  OfferSummaryBasePresenter,
} from "./OfferSummaryBasePresenter";

export interface ICartGroup {
  name: string;
  items: IItemExport[];
}

export interface IOfferSummary {
  sections: ICartGroup[] | IExtensionSummary[];
  details: IDetailNote[];
  distributionData: IDistributionData;
  bannerLinks: IBannerLinks;
}

export interface IExtensionSummary {
  name: string;
  oncePrice: number | null;
  monthlyPrice: number | null;
  details: string;
  description: string;
}

/**
 * This class is responsible for the data that gets sent to the Backend to generate an offer pdf-file
 * @param {IOfferExport} offerExport - The offer to export.
 * @param {IDistributionData} distributionData - The distribution data comes from the user profile and is optional.
 * @description - Use offerExport param and map it into a payload that will then be used for the pdf generation in the backend.
 * Each section has a name and a list of items. Each item has a name, a price and a list of details. Each detail has a headline and a text.
 */
export class OfferSummaryPresenter extends OfferSummaryBasePresenter {
  constructor(
    private offerExport: IOfferExport,
    protected bannerLinksData: IBannerLinks,
    protected baseDistributionData: IDistributionData
  ) {
    super(
      bannerLinksData,
      baseDistributionData,
      offerExport.details,
      offerExport.customerNote,
      false,
      false,
      null,
    );
    this.offerExport = offerExport;
  }

  private static exportExtensionSummary(
    extensionItem: IExtension
  ): IExtensionSummary {
    return {
      name: extensionItem.name,
      oncePrice: extensionItem.price.once,
      monthlyPrice: extensionItem.price.monthly,
      details: extensionItem.productDescription.description,
      description: `${extensionItem.productDescription.summary}${extensionItem.productDescription.text}`,
    };
  }

  /**
   * @param {ICartItemData} item - The product in a cart that needs to be mapped for an export.
   * @param {boolean} isLandline - Indicates if it's a landline.
   * @param {IBenefit} [benefit] - The benefit associated with the item. Only landline tariff or mobile tariff could have this.
   * @param {string} [note] - Additional notes for the item.
   * @returns {IItemExport} The exported item summary.
   * @description It uses CartItemSummaryPresenter and maps the CartItemData into a structure that can be consumed by our pdf API. Refactor this entire approach in general...
   */
  private exportItemSummary(
    item: ICartItemData,
    isLandline,
    benefit?: IBenefit,
    note?: string
  ): IItemExport {
    let settings = isLandline
      ? this.offerExport.cartSummary.landlineSettings
      : this.offerExport.cartSummary.mobileSettings;
    const presenter = new CartItemSummaryPresenter(item, settings);

    const isMobileTariff = item.group === Group.tariff && isLandline === false;

    if (isMobileTariff) {
      settings = settings as IMobileSettings;
      return presenter.cartItemBaseSummaryExport(benefit, note, settings.loyaltyBonusValue);
    }

    return presenter.cartItemBaseSummaryExport(benefit, note);
  }

  private get cartGroups(): ICartGroup[] {
    const sections = [] as ICartGroup[];
    let multiSimSummary = [] as IItemExport[];

    const {
      landlineTariff,
      mobileTariff,
      landlineOptions,
      mobileOptions,
      cards,
      multiSimCards,
      devices,
      notes,
      benefits,
    } = this.offerExport.cartSummary;

    if (landlineTariff) {
      sections.push({
        name: "Zuhause",
        items: [
          this.exportItemSummary(
            landlineTariff,
            true,
            // hotfix: remove landline benefit if mobile prepaid is activated
            benefits.isActive &&
              mobileTariff?.tariffWorld.key !==
                TariffWorldKeys.magenta_mobile_prepaid
              ? benefits.landlineBenefit
              : null,
            notes.landline
          ),
        ],
      });

      if (landlineTariff.extension) {
        sections.push({
          name: "Netflix Option",
          items: [
            OfferSummaryPresenter.exportExtensionSummary(
              landlineTariff.extension
            ),
          ],
        });
      }
    }

    if (devices.length)
      sections.push({
        name: "Geräte",
        items: devices.map((items) => this.exportItemSummary(items, true)),
      });

    if (landlineOptions.length)
      sections.push({
        name: "Extras Festnetz",
        items: landlineOptions.map((items) =>
          this.exportItemSummary(items, true)
        ),
      });

    if (multiSimCards.length) {
      multiSimSummary = multiSimCards.map((items) =>
        this.exportItemSummary(items, false)
      );
    }

    if (mobileTariff) {
      sections.push({
        name: "Mobilfunk",
        items: [
          this.exportItemSummary(
            mobileTariff,
            false,
            benefits.isActive ? benefits.mobileBenefit : null,
            notes.mobile
          ),
          ...multiSimSummary,
        ],
      });
    } else {
      sections.push({
        name: "Mobilfunk",
        items: multiSimSummary,
      });
    }

    if (cards.length) {
      sections.push({
        name: "Zusatzkarten",
        items: cards.map((items) =>
          this.exportItemSummary(items, false, null, items.cardData.note)
        ),
      });
    }

    if (mobileOptions.length)
      sections.push({
        name: "Extras Mobilfunk",
        items: mobileOptions.map((items) =>
          this.exportItemSummary(items, false)
        ),
      });

    return sections;
  }

  exportSummary(): IOfferSummary {
    return {
      ...this.export(),
      sections: this.cartGroups,
    };
  }
}
