import { IDiscount } from "../Product/IDiscount";
import { Group, PortfolioKeys } from "../Product/IProduct";
import { IPromotionSectionsKeys } from "./IPromotionSectionsKeys";

export interface IPromotionSections {
  [PortfolioKeys.LANDLINE]: ICustomPromotion[];
  [PortfolioKeys.MOBILE]: ICustomPromotion[];
  [Group.card]: ICustomPromotion[];
}

export interface ICustomPromotion {
  key: string;
  name: string;
  // null is for customPromotions from SecondaryCards, and custom promotions that could come from MagentaCalculator
  type: CustomPromotionTypes | null;
  description: string;
  portfolio: IPromotionSectionsKeys;
  discount: IDiscount;
  contractPeriod?: number;
  isEditable: boolean;
}

export interface ICustomPromotionSections {
  [PortfolioKeys.LANDLINE]: ICustomPromotion[];
  [PortfolioKeys.MOBILE]: ICustomPromotion[];
}

export enum CustomPromotionTypes {
  PROMOTION_TYPE_MONTHLY_REDUCE = "monthly",
  PROMOTION_TYPE_ONCE_REDUCE = "once",
  PROMOTION_TYPE_WITHOUT_PRICE = "without_price",
  PROMOTION_TYPE_CASHBACK = "cashback",
}
